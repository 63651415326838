import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import Notification from "../../../components/Notification/Notification";
import { peticionesReceiver } from "../../../helpers/peticionesReceiver";
import TableComponentCustom from "../../../components/TableComponent/TableComponentCustom";
import { Modal, ModalBody } from "reactstrap";
import {
  SelectTypeHeadSingle,
  TextInput,
} from "../../../components/GenericInputsFormik/index";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Col, Row, Button } from "reactstrap";
import { useHistory } from "react-router-dom";
import Widget from "../../../components/Widget/Widget";
import ModalConfirmation from "../../../components/Modal/ModalConfirmation";
import ModalReports from "../../../components/Modal/ModalReports";
import { generarGafete } from "./Pdf/pdfGafete";
import QRCode from "qrcode.react";
import Loader from "../../../components/Loader/Loader";
import { peticionEncript } from "../../../helpers/peticionesEncripted";

const Gafetes = () => {
  const urlGafete =
    "https://pruebas.sapptesting.click/sapp/back/app/view/verificatedNameTags.php?token=";
  const idStaff = localStorage.getItem("dataContent");
  const [staff, setStaff] = useState("");
  const [status, setStatus] = useState("");
  const [modal, setModal] = useState(false);
  const [modalConfirm, setModalConfirm] = useState(false);
  const [modalReports, setModalReports] = useState(false);
  const [nameTag, setNameTag] = useState("");
  const [pdfConfig, setPdfConfig] = useState({});
  const [complementUrlGafete, setComplementUrlGafete] = useState("");

  const toggle = () => setModal(!modal);
  const toggleConfirm = () => setModalConfirm(!modalConfirm);
  const CloseBtn = (
    <i className="eva eva-close cursor-pointer" onClick={toggle} />
  );

  const API = peticionesReceiver();
  const history = useHistory();

  const consultaGeneral = {
    action: "datatable",
    table:
      "nameTags nts left join templatesNameTags tnt on (nts.idTemplateNameTag = tnt.idTemplateNameTags) INNER JOIN staff stf on (stf.idStaff = nts.idStaff) INNER JOIN status stat on (stat.keyStat = nts.keyStat) INNER JOIN jobstaff job on (stf.idStaff = job.idStaff) INNER JOIN employtypes emt on (stf.keyEmployType = emt.keyEmployType)",
    rows: "nts.idNameTags,stf.staffNumber,nts.folio,emt.nameEmployType,tnt.nameTemplateNameTag,stat.nameStat,job.endDate,nts.validDate,nts.keyStat",
    conditions: `stf.idStaff = ${idStaff} and nts.enabled = 1 and job.valid = 1`,
    page: 0,
    records: 5,
    search: "",
    order: "stat.nameStat ASC",
  };

  const cabeceras = [
    "Id",
    "Numero de empleado",
    "Folio Gafete",
    "Tipo Gafete",
    "Plantilla Gafete",
    "Estatus",
    "Vigencia",
    "Fecha de baja",
    "Modificar Estatus",
    "Ver",
  ];
  const [parametros, setParametros] = useState(consultaGeneral);
  const filtro = [
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    false,
    false,
    false,
    false,
    false,
    false,
  ];

  useEffect(() => {
    getStaff();
    getStatus();
  }, []);

  const ver = (values) => {
    console.log("VALORES", values);
    var paramsGafetes = {
      action: "validateNameTag",
      idNameTags: values[0],
    };
    const complement = peticionEncript(paramsGafetes);
    setComplementUrlGafete({
      complementUrlGafete: complement,
      idNameTag: values[0],
    });

    /* console.log("PARAMS GAFETES", paramsGafetes)
     console.log("URL FINAL", urlGafete+complementUrlGafete)
     console.log("URL FINAL comlement", urlGafete+complement) */

    //se enviara al metodo al useEffect para esperar el cambio
  };
  const getStaff = async () => {
    const params = {
      action: "getStaffData",
      idStaff: idStaff,
    };
    await API.peticionEndPoint(params, "app/facades/reports/reportsF.php")
      .then((res) => {
        if (res.status === 200 && res.data.code === "200") {
          setStaff(res.data.data[0]);
        } else {
          toast(
            <Notification
              type={"consultar_error"}
              backMessage={res.data.message}
              withIcon
            />
          );
        }
      })
      .catch((err) => {
        toast(<Notification type={"consultar_servidor_error"} withIcon />);
      });
  };
  const getStatus = async () => {
    const data = {
      action: "multiselect",
      table: "status stat",
      rows: "stat.keyStat,stat.nameStat",
      conditions: `stat.enabled = 1 and stat.keyTypeStat = 15`,
    };

    let stats = [];
    API.peticion(data)
      .then((res) => {
        if (res.status === 200 && res.data.status === "success") {
          stats = res.data.data;
          stats = stats.filter(
            (stat) =>
              stat.nameStat !== "ACTIVO" &&
              stat.nameStat !== "BAJA" &&
              stat.nameStat !== "BAJA "
          );

          setStatus(stats);
        } else {
          toast(
            <Notification
              type={"consultar_error"}
              backMessage={res.data.message}
              withIcon
            />
          );
        }
      })
      .catch((err) => {
        toast(<Notification type={"consultar_error"} withIcon />);
      });
  };

  const [defaultForm] = useState({
    idStaff: idStaff,
    keyStat: "",
    dueDate: "",
  });

  const FormSchema = Yup.object().shape({
    keyStat: Yup.string().required("Seleccione un estatus"),
    dueDate: Yup.string().required("Seleccione una fecha de baja"),
  });

  const {
    handleSubmit,
    values,
    handleBlur,
    errors,
    touched,
    setFieldValue,
    resetForm,
  } = useFormik({
    initialValues: defaultForm,
    onSubmit: (values) => {
      setModalConfirm(true);
    },
    validationSchema: FormSchema,
    enableReinitialize: true,
  });

  const onChange = (e) => {
    setFieldValue([e.target.name], e.target.value);
  };

  const prepareEdit = (nameTag) => {
    setFieldValue("keyStat", nameTag[8]);
    setNameTag(nameTag);

    setModal(true);
  };

  const editStat = (values) => {
    let params = {
      action: "saveStatusNameTag",
      rows: {
        idNameTags: nameTag[0],
        keyStat: values.keyStat,
        dueDate: values.dueDate,
        idStaff: idStaff,
      },
    };
    API.peticionEndPoint(params, "app/facades/employees/nameTagsF.php")
      .then((res) => {
        if (res.status === 200 && res.data.code === "200") {
          setModal(false);
          setParametros(consultaGeneral);
          resetForm();
          toast(
            <Notification
              type={"agrega_exito"}
              backMessage={res.data.message}
              withIcon
            />
          );
        } else {
          toast(
            <Notification
              type={"consultar_error"}
              backMessage={res.data.message}
              withIcon
            />
          );
        }
      })
      .catch((err) => {
        toast(<Notification type={"consultar_servidor_error"} withIcon />, {
          closeButton: false,
        });
      });
  };

  useEffect(() => {
    if (complementUrlGafete !== "")
      generatedQrImage(complementUrlGafete.idNameTag);
  }, [complementUrlGafete]);

  const generatedQrImage = (idNameTag) => {
    const canvas = document.getElementById("123456");
    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    setPdfConfig({
      pngUrl: pngUrl,
      idNameTags: idNameTag,
    });

    console.log(urlGafete + complementUrlGafete.complementUrlGafete);
    setModalReports(true);
  };

  return (
    <Widget>
      <Row className="d-flex justify-content-end">
        <Col className="text-right m-5" xs={12} lg={6}>
          <button
            className="btn text-light"
            style={{ backgroundColor: "#cc0a44", border: "none" }}
            onClick={() => history.push("/template/gafetes")}
          >
            Cerrar expediente
          </button>
        </Col>
      </Row>

      {staff !== "" ? (
        <TableComponentCustom
          titulo={`GAFETES DE ${staff.name ? staff.name : ""}`}
          cabecerasTabla={cabeceras}
          filtro={filtro}
          rutaCrear="/template/gafetes/crear"
          parametros={parametros}
          addNew={true}
          addDelete={false}
          addEdit={true}
          actionEdit={prepareEdit}
          addWatch={true}
          actionWatch={ver}
        />
      ) : (
        <Loader />
      )}

      <Modal
        isOpen={modal}
        backdrop={true}
        keyboard={true}
        toggle={toggle}
        style={{ minWidth: "95%", marginTop: "300px" }}
      >
        <form onSubmit={handleSubmit} className="mt-4">
          <ModalBody className="mx-2">
            <Row className="d-flex justify-content-end">{CloseBtn}</Row>
            <h1 className="text-secondary text-center">
              Modificación de estatus de gafete{" "}
            </h1>
            <Row className="d-flex flex-column justify-content-center align-items-center text-center">
              <Col xs={12} md={12} lg={6}>
                <SelectTypeHeadSingle
                  label="Estatus"
                  isRequired={true}
                  inputName="keyStat"
                  optionsArray={status}
                  defaultOption="Seleccione un estatus"
                  onChangeMethod={onChange}
                  onBlur={handleBlur}
                  isDisabled={false}
                  value={values.keyStat}
                  touched={touched.keyStat}
                  errors={errors.keyStat}
                  optionName="nameStat"
                  optionValue="keyStat"
                />
              </Col>
              <Col xs={12} lg={6}>
                <TextInput
                  label="Fecha de baja"
                  inputType="date"
                  inputName="dueDate"
                  onChangeMethod={onChange}
                  onBlurMethod={handleBlur}
                  value={values.dueDate}
                  isRequired
                  touched={touched.dueDate}
                  errors={errors.dueDate}
                />
              </Col>

              <Col className="text-center" xs={12} md={12} lg={6}>
                <Button
                  color="success"
                  className="text-center mt-3"
                  type="submit"
                >
                  Actualizar Estatus
                </Button>
              </Col>
            </Row>
            <ModalConfirmation
              modalTitle={"Editar estatus"}
              modal={modalConfirm}
              setModal={setModalConfirm}
              editar={editStat}
              isEdit={true}
              values={values}
              reset={resetForm}
            >
              <div className="d-flex justify-content-center">
                <h6>¿Desea editar el registro?</h6>
              </div>
            </ModalConfirmation>
          </ModalBody>
        </form>
      </Modal>

      <div>
        <ModalReports
          modal={modalReports}
          setModal={setModalReports}
          title={"Gafete"}
          cabeceras={""}
          backdrop={"static"}
          keyboard={false}
          report={"rep"}
          pdfConfig={pdfConfig}
          generatePdfMethod={{
            method: generarGafete,
            type: "general",
          }}
          records={1000}
        />
      </div>

      <div style={{ display: "none" }}>
        <QRCode
          id="123456"
          value={
            urlGafete +
            (complementUrlGafete !== ""
              ? encodeURIComponent(complementUrlGafete.complementUrlGafete)
              : "")
          }
          size={290}
          level={"H"}
          includeMargin={true}
        />
      </div>
    </Widget>
  );
};

export default Gafetes;

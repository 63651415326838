import React, { useState, useEffect, useRef } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import parse from "date-fns/parse";
import differenceInDays from "date-fns/differenceInDays";
import { toast } from "react-toastify";
import { Col, Row, Button, Collapse } from "reactstrap";
import "react-datepicker/dist/react-datepicker.css";
import Notification from "../../../components/Notification/Notification";
import ModalConfirmation from "../../../components/Modal/ModalConfirmation";
import getDaysInMonth from "date-fns/getDaysInMonth";
import {
  TextInput,
  SelectTypeHeadSingle,
  FilesInput,
  TextAreaInput,
} from "../../../components/GenericInputsFormik/index";
import {
  getApplicationSalaries,
  getKindToStatus,
  getIncapacitiesType,
  getStatusPeriod,
} from "./consultasSelect";
import { doRequest, uploadFile } from "../../../helpers/requests";
import TableComponentCustom from "../../../components/TableComponent/TableComponentCustom";
import ModalViewFile from "../../../components/Modal/ModalViewFile";
import ModalComponentEliminar from "../../../components/Modal/ModalDelete";
import FormaDescuentoForm from "./extra-forms/FormaDescuentoForm";
import FullFormLoader from "../../../components/Loader/FullFormLoader";
import { DatePickerInput } from "../../../components/GenericInputsFormik/DatePickerInput";
import { fDateInput } from "../../../libs/tools/format";

const currDate = new Date();

const defaultForm = {
  idStaff: "",
  idIncapacity: "",
  folio: "",
  clinicalDiagnosis: "",
  clinicalSpecialty: "",
  medicalUnit: "",
  doctor: "",
  keyTypeIncapacity: "",
  keyStat: "",
  policy: "",
  startDate: "",
  endDate: "",
  noDays: 0,
  application: [],
  notes: "",
  comprobante: "",
  // forPeriod: "",
  // amountTotal: "",
  // amountBiweekly: "",
  // noPays: "",
  // noPaysMade: "",
  // startPeriod: "",
  // startYear: currDate,
  // endPeriod: "",
  // endYear: "",
};

export const ENIncapacidadesRF = ({ idStaff, permissions }) => {
  const refInpFile = useRef(null);
  const [modalFile, setModalFile] = useState(false); ///Visualziar archivos
  const [linkFile, setLinkFile] = useState(""); ///Link de archivo
  const [idHistorico, setIdHistorico] = useState([]);
  const [currPeriodDate, setCurrPeriodDate] = useState({});
  const [selectsData, setSelectsData] = useState({
    incapacityTypes: [],
    statusTypes: [],
    applicationSalaryTypes: [],
    statusPeriod: [],
  });
  // --------  INFORMACION PARA EL  BACK -------- //
  const [finalData, setFinalData] = useState({});
  const [discounts, setDiscounts] = useState([]);

  // -------- PARA LA TABLA ONE PAGE Y FORMULARIO --------
  const [editando, setEditando] = useState(false);
  const [parametros, setParametros] = useState({
    action: "datatable",
    table:
      "incapacitys A INNER JOIN types_incapacitys B on B.keyTypeIncapacity = A.keyTypeIncapacity INNER JOIN status C on C.keyStat = A.keyStat LEFT JOIN bookcase D ON D.reference = A.idIncapacity AND D.keyFileType=47 LEFT JOIN files E ON E.idBook = D.idBook LEFT JOIN incapacityapplication F USING(idIncapacity) INNER JOIN types_applicationsalary G USING(keyTypeApplicationSalary)",
    rows: "A.idIncapacity, A.folio, A.startDate, A.endDate, A.noDays, A.medicalUnit, A.clinicalDiagnosis,B.nameTypeIncapacity,C.nameStat,group_concat(DISTINCT G.nameTypeApplicationSalary SEPARATOR ', ') formasAplicacion,E.src",
    conditions: `A.enabled=1 AND A.idStaff=${idStaff} AND IF(ISNULL(E.enabled),true,E.enabled=1) AND F.enabled=1`,
    page: 0,
    records: 5,
    search: "",
    order: "",
    group:
      "A.idIncapacity, A.folio, A.startDate, A.endDate, A.noDays, A.medicalUnit, A.clinicalDiagnosis, B.nameTypeIncapacity, C.nameStat, E.src",
  });
  const [cabeceras] = useState([
    "Id",
    "Folio",
    "Fecha de inicio",
    "Fecha de termino",
    "No. Dias",
    "Unidad Medica",
    "Diagnostico",
    "Tipo de incapacidad",
    "Estatus",
    "Formas de aplicación",
    "Evidencia",
    "Editar",
    "Eliminar",
  ]);
  const [collapse, setCollapse] = useState(false);
  const [filtro] = useState([
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
  ]);

  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(true);

  /************* CONSULTA INICIAL *************/
  useEffect(() => {
    getKindToStatus(
      setSelectsData,
      true,
      "statusTypes",
      "INCAPACITYS",
      setIdHistorico
    );
    getActualPeriodDate();
    getIncapacitiesType(setSelectsData, true, "incapacityTypes");
    getApplicationSalaries(setSelectsData, true, "applicationSalaryTypes");
    getStatusPeriod(setSelectsData);
  }, []);

  const getActualPeriodDate = () => {
    const segMi = currDate.getDate() > 15; // mitad del mes
    const startDateCurrPeriod = new Date(
      currDate.getFullYear(),
      currDate.getMonth(),
      segMi ? 16 : 1
    ); // fehca inicial del periodo
    var ed = new Date(startDateCurrPeriod);
    ed.setDate(ed.getDate() - 1); // fin del periodo anterior
    const midDays = segMi ? 15 : getDaysInMonth(ed) - 15; // mitad de dias del mes del periodo anterior
    var sd = new Date(startDateCurrPeriod);
    sd.setDate(sd.getDate() - midDays); // inicio del periodo anterior
    setCurrPeriodDate({
      firstDayPeriod: sd,
      endDayPeriod: ed,
    });
  };
  /************* END CONSULTA INICIAL *************/

  /************* FORM *************/
  const FormSchema = Yup.object().shape({
    keyTypeIncapacity: Yup.string().required(
      "Seleccione un tipo de incapacidad"
    ),
    keyStat: Yup.string().required("Seleccione un estatus"),
    startDate: Yup.date()
      .transform(function (value, originalValue) {
        if (this.isType(value)) return value;
        const result = parse(originalValue, "dd.MM.yyyy", new Date());
        return result;
      })
      .typeError("Ingrese una fecha valida")
      .required("La fecha de inicio es requerida")
      .min("2000-01-01", "Fecha invalida"),
    endDate: Yup.date()
      .transform(function (value, originalValue) {
        if (this.isType(value)) return value;
        const result = parse(originalValue, "dd.MM.yyyy", new Date());
        return result;
      })
      .typeError("Ingrese una fecha valida")
      .required("La fecha de termino es requerida")
      .min(
        Yup.ref("startDate"),
        "La fecha de finalización debe ser mayor o igual a la de inicio"
      ),
    noDays: Yup.number()
      .required("Seleccione el rango de fechas")
      .min(1, "El minimo de dias debe ser mayor a 0")
      .test(
        "maxTtDays",
        "Días de las formas de descuento no coinciden",
        (value) => {
          return validDays(value);
        }
      ),
    comprobante: Yup.mixed().when("idIncapacity", {
      is: (v) => !v,
      then: Yup.mixed()
        .required("Agregue un comprobante")
        .test("file-type", "El documento debe ser en formato PDF", (value) => {
          return value && value.type === "application/pdf";
        })
        .test({
          message: `El documento debe pesar menos de 5MB`,
          test: (value) => value?.size < 5000000,
        }),
    }),
  });

  function validDays(ttd) {
    const sum = discounts.reduce(
      (acc, d) => acc + (+d.enabled > 0 ? +d.noDays : 0),
      0
    );
    return ttd === sum;
  }

  const {
    handleSubmit,
    handleReset,
    values,
    handleBlur,
    errors,
    touched,
    setFieldValue,
    setValues,
  } = useFormik({
    initialValues: { ...defaultForm, idStaff: idStaff },
    onSubmit: (values) => setModal(true),
    onReset: () => cleanData(),
    validationSchema: FormSchema,
    enableReinitialize: true,
  });

  const cleanData = () => {
    setDiscounts([]);
    setEditando(false);
    setCollapse(false);
    refInpFile.current.value = "";
    setFieldValue("comprobante", "");
  };

  const onChange = ({ target }) => {
    let val = target.value;
    if (target.name === "keyStat") {
      setFieldValue("startDate", "");
      setFieldValue("endDate", "");
      setDiscounts([]);
    }
    if (target.name === "comprobante") {
      val = target.files[0];
    }
    setFieldValue(target.name, val);
  };

  // const enviaDatos = () => {
  //   setFinal-Data({
  //     idStaff: idStaff,
  //     idIncapacity: values.idIncapacity,
  //     folio: values.folio,
  //     clinicalDiagnosis: values.clinicalDiagnosis,
  //     clinicalSpecialty: values.clinicalSpecialty,
  //     medicalUnit: values.medicalUnit,
  //     doctor: values.doctor,
  //     keyTypeIncapacity: values.keyTypeIncapacity,
  //     keyStat: values.keyStat,
  //     policy: values.policy,
  //     startDate: formatDate(values.startDate),
  //     endDate: formatDate(values.endDate),
  //     noDays: values.noDays,
  //     application: discounts, /// aplications
  //     notes: values.notes,

  //     /* Campos obsoletos*/
  //     // forPeriod: values.forPeriod ? 1 : 0,
  //     /* Cada periodod de cada aplciacion*/
  //     // amountTotal: 0,
  //     // amountBiweekly: incapacityData.amountBiweekly,
  //     // noPays: values.noPays,
  //     // noPaysMade: values.noPaysMade,
  //     // startPeriod: values.startPeriod,
  //     // startYear: values.startYear ? values.startYear.getFullYear() : "",
  //     // endPeriod: values.endPeriod,
  //     // endYear: values.endYear,
  //   });
  //   setModal(true);
  // };

  const sendFormInformation = async () => {
    setLoading(true);
    const params = {
      action: "saveIncapacitys",
      rows: {
        ...values,
        application: discounts,
        startDate: fDateInput(values.startDate),
        endDate: fDateInput(values.endDate),
      },
    };
    delete params.rows.comprobante;
    const res = await doRequest(
      "app/facades/employees/benefitsF.php",
      params,
      true
    );
    if (res.length > 0) {
      if (values.comprobante) {
        await uploadFile(
          res[0].idIncapacity,
          "saveFileReplace",
          47,
          values.comprobante,
          false,
          true
        );
      }
      handleReset();
      setParametros({ ...parametros });
    }
    setLoading(false);
  };
  /************* END FORM *************/

  /************* INICIO DE LAS FUNCIONES *************/

  function padTo2Digits(num) {
    return num.toString().padStart(2, "0");
  }

  useEffect(() => {
    if (
      selectsData.applicationSalaryTypes.length > 0 &&
      selectsData.incapacityTypes.length > 0 &&
      selectsData.statusTypes.length > 0 &&
      loading
    ) {
      setLoading(false);
    }
  }, [selectsData]);

  /************* TABLE *************/
  const PdfCol = (el) => {
    return (
      <i
        className="fa fa-file-pdf-o cursor-pointer"
        onClick={() => showFile(el[10])}
      />
    );
  };

  const editCol = (el) => {
    return (
      permissions.UPD && (
        <i
          className="fa fa-pencil text-warning cursor-pointer"
          onClick={() => modoEdicion(el)}
        />
      )
    );
  };

  const modoEdicion = async (row) => {
    if (!collapse) {
      await getApplicationByIdIncapacity(row);
    } else {
      toast(
        <Notification
          type="warning"
          backMessage="Guarde sus cambios antes de continuar"
        />,
        { closeButton: false }
      );
    }
  };

  const getApplicationByIdIncapacity = async (row) => {
    setLoading(true);
    const data = {
      action: "searchIncapacitysById",
      rows: {
        idIncapacity: row[0],
      },
    };
    const res = await doRequest(
      "app/facades/employees/benefitsF.php",
      data,
      false
    );
    if (res.length > 0) {
      const info = res[0];
      let startDate = new Date(info.startDate);
      let endDate = new Date(info.endDate);
      startDate.setDate(startDate.getDate() + 1);
      endDate.setDate(endDate.getDate() + 1);
      setValues(
        {
          idStaff: idStaff,
          idIncapacity: info.idIncapacity,
          folio: info.folio,
          clinicalDiagnosis: info.clinicalDiagnosis,
          clinicalSpecialty: info.clinicalSpecialty,
          medicalUnit: info.medicalUnit,
          doctor: info.doctor,
          keyTypeIncapacity: info.keyTypeIncapacity,
          keyStat: info.keyStat,
          policy: info.policy,
          startDate: startDate,
          endDate: endDate,
          noDays: info.noDays,
          application: info.application,
          notes: info.notes,

          // amountTotal: info.amountTotal,
          // startYear: new Date(info.startYear, 0, 1),
          // startPeriod: info.startPeriod,
          // endYear: info.endYear,
          // endPeriod: info.endPeriod,
          // amountBiweekly: info.amountBiweekly,
          // noPays: info.noPays,
          // noPaysMade: info.noPaysMade,
          // forPeriod: +info.forPeriod === 1 ? true : false,
        },
        true
      );
      setDiscounts(info.application);
      setEditando(true);
      setCollapse(true);
    }
    setLoading(false);
  };

  const deleteCol = (el) => {
    return (
      permissions.DEL && (
        <ModalComponentEliminar
          modalTitle="Eliminar"
          id={el[0]}
          table="incapacitys"
          nameColumn={["idIncapacity"]}
          elimina={elimianrRegistro}
        >
          <h6>¿Seguro de eliminar el registro "{el[1]}"?</h6>
          No podrá recuperar la información después de ser eliminada
        </ModalComponentEliminar>
      )
    );
  };

  async function elimianrRegistro(id) {
    const params = {
      action: "delete",
      table: "incapacitys",
      condition: { idIncapacity: id },
      force: 1,
    };
    setLoading(true);
    const data = await doRequest("receiver/receiver.php", params, true);
    if (data.length > 0) {
      handleReset();
      setParametros({ ...parametros });
    }
    setLoading(false);
  }

  function showFile(src) {
    if (src === "") {
      toast(
        <Notification
          type={"warning"}
          backMessage="Este registro no cuenta con una evidencia"
          withIcon
        />,
        { closeButton: false }
      );
    } else setLinkFile(src);
  }
  /************** END TABLE **************/

  // Calculcar cantidad de dias
  useEffect(() => {
    if (values.startDate & values.endDate) {
      const ttDays = differenceInDays(values.endDate, values.startDate);
      setFieldValue("noDays", ttDays + 1);
    } else setFieldValue("noDays", "");
  }, [values.startDate, values.endDate]);

  // Abrir modal
  useEffect(() => {
    if (linkFile !== "") setModalFile(true);
  }, [linkFile]);

  // Cerrar modal
  useEffect(() => {
    if (!modalFile) setLinkFile("");
  }, [modalFile]);

  return (
    <div className="mt-4">
      <TableComponentCustom
        titulo="Incapacidades"
        cabecerasTabla={cabeceras}
        filtro={filtro}
        parametros={parametros}
        specialColumns={[PdfCol, editCol, deleteCol]}
        defaultOrder="A.created DESC"
      />

      <div className="d-flex flex-column-reverse flex-md-row justify-content-md-between text-center mb-1">
        <div>
          {collapse && (
            <h4 className="my-3 my-md-2">{`${
              values.idIncapacity ? "Editar" : "Agregar"
            } Incapacidad`}</h4>
          )}
        </div>
        {permissions.INS && (
          <Button
            color="add"
            onClick={() => setCollapse(true)}
            disabled={collapse}
          >
            Agregar
          </Button>
        )}
      </div>

      <Collapse isOpen={collapse}>
        <form
          onSubmit={handleSubmit}
          onReset={handleReset}
          className="mt-0 mt-md-4"
          noValidate
          id="mainForm"
        >
          <Row className="mt-2">
            <Col xs={12} md={12} lg={4}>
              <TextInput
                label="Folio de incapacidad"
                inputType="text"
                inputName="folio"
                onChangeMethod={onChange}
                onBlurMethod={handleBlur}
                value={values.folio}
                touched={touched.folio}
                errors={errors.folio}
              />
            </Col>
            <Col xs={12} md={12} lg={4}>
              <TextInput
                label="Oficios"
                inputType="text"
                inputName="policy"
                onChangeMethod={onChange}
                onBlurMethod={handleBlur}
                value={values.policy}
                touched={touched.policy}
                errors={errors.policy}
              />
            </Col>
            <Col xs={12} md={12} lg={4}>
              <SelectTypeHeadSingle
                label="Tipo de incapacidad"
                isRequired={true}
                inputName="keyTypeIncapacity"
                optionsArray={selectsData.incapacityTypes}
                defaultOption="Seleccione un tipo de incapacidad"
                onChangeMethod={onChange}
                onBlurMethod={handleBlur}
                isDisabled={false}
                value={values.keyTypeIncapacity}
                touched={touched.keyTypeIncapacity}
                errors={errors.keyTypeIncapacity}
                optionValue="keyTypeIncapacity"
                optionName="nameTypeIncapacity"
              />
            </Col>
            <Col xs={12} md={12} lg={4}>
              <SelectTypeHeadSingle
                label="Estatus"
                isRequired={true}
                inputName="keyStat"
                optionsArray={selectsData.statusTypes}
                defaultOption="Seleccione un estatus"
                onChangeMethod={onChange}
                onBlurMethod={handleBlur}
                isDisabled={false}
                value={values.keyStat}
                touched={touched.keyStat}
                errors={errors.keyStat}
                optionValue="keyStat"
                optionName="nameStat"
              />
            </Col>
          </Row>
          <Row className="mt-2">
            <Col xs={12} md={12} lg={4}>
              <TextInput
                label="Diagnostico"
                inputType="text"
                inputName="clinicalDiagnosis"
                onChangeMethod={onChange}
                onBlurMethod={handleBlur}
                value={values.clinicalDiagnosis}
                touched={touched.clinicalDiagnosis}
                errors={errors.clinicalDiagnosis}
              />
            </Col>
            <Col xs={12} md={12} lg={4}>
              <TextInput
                label="Especialidad"
                inputType="text"
                inputName="clinicalSpecialty"
                onChangeMethod={onChange}
                onBlurMethod={handleBlur}
                value={values.clinicalSpecialty}
                touched={touched.clinicalSpecialty}
                errors={errors.clinicalSpecialty}
              />
            </Col>
            <Col xs={12} md={12} lg={4}>
              <TextInput
                label="Clinica"
                inputType="text"
                inputName="medicalUnit"
                onChangeMethod={onChange}
                onBlurMethod={handleBlur}
                value={values.medicalUnit}
                touched={touched.medicalUnit}
                errors={errors.medicalUnit}
              />
            </Col>
            <Col xs={12} md={12} lg={4}>
              <TextInput
                label="Nombre del medico"
                inputType="text"
                inputName="doctor"
                onChangeMethod={onChange}
                onBlurMethod={handleBlur}
                value={values.doctor}
                touched={touched.doctor}
                errors={errors.doctor}
              />
            </Col>
            <Col xs={12} md={12} lg={4}>
              <FilesInput
                label="Comprobante"
                inputName="comprobante"
                onChangeMethod={onChange}
                onBlurMethod={handleBlur}
                fileAccept="application/pdf"
                isRequired
                touched={touched.comprobante}
                errors={errors.comprobante}
                basicReq={false}
                refInput={refInpFile}
              />
            </Col>
          </Row>
          <Row className="mt-2">
            <Col xs={12} md={12} lg={4}>
              <DatePickerInput
                label=" Fecha de inicio"
                inputName="startDate"
                onChangeMethod={onChange}
                onBlurMethod={handleBlur}
                value={values.startDate}
                touched={touched.startDate}
                errors={errors.startDate}
                isMax={
                  values.keyStat === idHistorico.keyStat &&
                  currPeriodDate.endDayPeriod
                }
                isDisabled={!values.keyStat}
                isRequired
              />
            </Col>
            <Col xs={12} md={12} lg={4}>
              <DatePickerInput
                label=" Fecha de término"
                inputName="endDate"
                onChangeMethod={onChange}
                onBlurMethod={handleBlur}
                value={values.endDate}
                touched={touched.endDate}
                errors={errors.endDate}
                isMin={values.startDate}
                isMax={
                  values.keyStat === idHistorico.keyStat &&
                  currPeriodDate.endDayPeriod
                }
                isDisabled={!values.startDate}
                isRequired
              />
            </Col>
            <Col xs={12} md={12} lg={4}>
              <TextInput
                label="No. días"
                inputType="number"
                inputName="noDays"
                onChangeMethod={onChange}
                onBlurMethod={handleBlur}
                value={values.noDays}
                errors={errors.noDays}
                touched={touched.noDays}
                isRequired={false}
                isDisabled={true}
              />
            </Col>
          </Row>

          <Collapse
            isOpen={!!values.startDate && !!values.endDate}
            onExited={() => setDiscounts([])}
          >
            <div>
              <FormaDescuentoForm
                tDescuentos={selectsData.applicationSalaryTypes}
                tStatus={selectsData.statusTypes}
                ttdays={values.noDays}
                discounts={discounts}
                setDiscounts={setDiscounts}
                idStaff={idStaff}
                reset={collapse}
                dates={{ sd: values.startDate, ed: values.endDate }}
                idIncapacity={values.idIncapacity}
              />
            </div>
          </Collapse>

          <div className="mt-2">
            <TextAreaInput
              label="Notas"
              inputName="notes"
              inputRows={5}
              onChangeMethod={onChange}
              onBlurMethod={handleBlur}
              value={values.notes}
            />
          </div>

          <div className=" d-flex flex-md-row flex-column-reverse justify-content-md-around">
            <Button color="danger" type="reset" className="mt-3">
              Cancelar
            </Button>
            <Button color="success" type="submit" className="mt-2 mt-md-3">
              Guardar Incapacidad
            </Button>
          </div>
        </form>
      </Collapse>

      <ModalConfirmation
        modalTitle={editando ? "Editar" : "Crear"}
        modal={modal}
        setModal={setModal}
        editar={sendFormInformation}
        values={finalData}
        isEdit
      >
        <div className="d-flex justify-content-center">
          <h6>¿Está seguro de {editando ? "editar" : "crear"} el registro?</h6>
        </div>
      </ModalConfirmation>

      <ModalViewFile
        modal={modalFile}
        setModal={setModalFile}
        backdrop={true}
        keyboard={true}
        btnClose={true}
        fileLink={linkFile}
      />

      <FullFormLoader show={loading} />
    </div>
  );
};
export default ENIncapacidadesRF;

import { Button, Col, Row, Spinner } from "reactstrap";
import Widget from "../../../components/Widget/Widget";
import Datatable from "../../../components/Datatable/Datatable";
import { peticionesReceiver } from "../../../helpers/peticionesReceiver";
import { toast } from "react-toastify";
import Notification from "../../../components/Notification/Notification";
import { useState } from "react";
import { useSelector } from "react-redux";
import { GetPermissions } from "../../../libs/permissions/getPermissions";
import { DragDropContainer, DropTarget } from "react-drag-drop-container";
import ModalCotizacion from "./modalCotizacion";
import ModalViewFile from "../../../components/Modal/ModalViewFile";
import * as Yup from "yup";
import { useFormik } from "formik";
import ButtonFile from "../../../components/GenericInputsFormik/ButtonFileInput";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { doRequest, uploadFile } from "../../../helpers/requests";
import ModalReports from "../../../components/Modal/ModalReports";
import pdfSolicitud from "../../presupuesto/dictamen-presupuestal/reports/pdfSolicitud";
import FullFormLoader from "../../../components/Loader/FullFormLoader";
import ModalConfirmation from "../../../components/Modal/ModalConfirmation";
import InputWithModal from "../../../components/GenericInputsFormik/InputWithModal";

const mNames = [
  "Enero",
  "Febrero",
  "Marzo",
  "Abril",
  "Mayo",
  "Junio",
  "Julio",
  "Agosto",
  "Septiembre",
  "Octubre",
  "Noviembre",
  "Diciembre",
];

export default function AgregarSolicitudAdquisicion() {
  const API = peticionesReceiver();
  const history = useHistory();
  const [permisos, setPermisos] = useState("");
  const [loading, setLoading] = useState("");
  const { profileType } = useSelector((state) => state.userInfo);
  const idArea = profileType.selectProfile.idArea;
  const [modal, showModal] = useState(false);
  GetPermissions(setPermisos, setLoading);
  const headers = ["Folio", "Monto cotizado", "Justificacion", "Acciones", ""];
  const [idQuote, setIdQuote] = useState("");
  const [idBudgetRequest, setIdBudgetRequest] = useState("");
  const [linkFile, setlinkFile] = useState();
  const [modalFile, setModalFile] = useState(false);
  const [idPurchaseRequest, setIdPurchaseRequest] = useState("");
  const [solicitud, setSolicitud] = useState(null);
  const [calend, setCalend] = useState(null);
  const [total, setTotal] = useState(null);
  const [notes, setNotes] = useState(null);
  const [proyP, setProyP] = useState(null);
  const [modalConfirmation, setModalConfirmation] = useState(false);
  const [added, setAdded] = useState(false);

  const [modalReport, setModalReport] = useState(false);

  const columns = [
    {
      data: null,
      render: function (row) {
        return row.numberQuote && row.yearQuote
          ? `${row.numberQuote}/${row.yearQuote}`
          : "";
      },
      orderValue: "CONCAT(q.numberQuote,'/',q.yearQuote)",
    },
    {
      data: "total",
      format: "currency",
    },
    { data: "object" },
    {
      data: "src",
      render: function render(row) {
        return (
          <Button
            color="info"
            size="sm"
            onClick={() => {
              setlinkFile(row);
              setModalFile(true);
            }}
            className="d-flex align-items-center text-nowrap py-1 px-2 mb-2 w-100"
          >
            <i className="fa fa-file-pdf-o mr-2" />
            Solicitud
          </Button>
        );
      },
    },
    {
      data: null,
      render: function render(row) {
        return (
          <>
            {(idQuote === "" || row.idQuote === idQuote) && (
              <DragDropContainer targetKey="foo" dragData={row} onDrop={onDrop}>
                <div className="border border-primary p-2 bg-white rounded">
                  <i className="fa fa-arrows" style={{ color: "blue" }} />
                </div>
              </DragDropContainer>
            )}
          </>
        );
      },
    },
  ];

  function onDrop({ dropData, dragData }) {
    if (Number(dropData.requestedAmount) < Number(dragData.total)) {
      toast(
        <Notification
          type={"consultar_error"}
          backMessage={"El monto cotizado supera el monto aprobado"}
        />
      );
    } else {
      setIdQuote(dragData.idQuote);
      setIdBudgetRequest(dropData.idBudgetRequest);
      showModal(true);
    }
  }

  const headers2 = [
    "",
    "Folio",
    "Justificación",
    "Monto aprobado",
    "Ver detalles",
  ];

  const columns2 = [
    {
      data: null,
      render: function render(row) {
        return (
          <>
            {(idBudgetRequest === "" ||
              row.idBudgetRequest === idBudgetRequest) && (
              <DropTarget targetKey="foo" dropData={row}>
                <div className="border border-primary p-2 bg-dark text-white text-center rounded">
                  Suelta <b>AQUI</b>
                </div>
              </DropTarget>
            )}
          </>
        );
      },
    },
    { data: "numberRequest" },
    { data: "justification" },
    {
      data: "requestedAmount",
      format: "currency",
    },
    {
      data: "idBudgetRequest",
      render: function render(row) {
        return (
          <Button
            color="info"
            size="sm"
            className="d-flex align-items-center text-nowrap py-1 px-2 mb-2 w-100"
            onClick={() => getDocument(row)}
          >
            <i className="fa fa-file-pdf-o mr-2" />
            Solicitud
          </Button>
        );
      },
    },
  ];

  const columnDefs2 = [
    { className: "text-center", targets: [1] },
    { orderable: false, targets: [0, 4] },
  ];

  const getParamsCotizaciones = (order, records, page, search) => {
    const params = {
      action: "datatable",
      table:
        "quotes q left join quotesdetail qd on (q.idQuote=qd.idQuote and qd.enabled=1) INNER JOIN bookcase BC ON (BC.reference = q.idQuote) LEFT JOIN files G ON (BC.idBook = G.idBook) INNER JOIN filetypes FT ON FT.keyFileType=BC.keyFileType",
      rows: "q.idQuote,q.numberQuote,q.yearQuote,q.object,SUM(qd.total) as total,G.src",
      conditions: `q.enabled=1 and q.keyStat = 106 and q.idArea=${idArea} AND BC.enabled = 1 AND G.enabled = 1 AND FT.enabled = 1 AND FT.keyFileType = 42 and q.idQuote NOT IN (SELECT idQuote FROM purchaserequests WHERE enabled = 1 AND keyStat != 124)`,
      group: `q.idQuote,q.numberQuote,q.yearQuote,q.object,qd.total,G.src`,
      order,
      records: records.toString(),
      page: (page * records).toString(),
      search,
    };
    return params;
  };

  async function getData(order, records, page, search) {
    try {
      const res = await API.peticion(
        getParamsCotizaciones(order, records, page, search)
      );
      if (res.status === 200 && res.data.code === "200") {
        return res.data;
      } else {
        toast(
          <Notification
            type={"consultar_error"}
            backMessage={res.data.message}
          />
        );
        return null;
      }
    } catch (err) {
      toast(<Notification type={"consultar_servidor_error"} withIcon />);
      return null;
    }
  }

  const getParamsSuficiencia = (order, records, page, search) => {
    const params = {
      action: "datatable",
      table: "budgetrequests A INNER JOIN status B on (A.keyStat = B.keyStat) ",
      rows: "A.idBudgetRequest,A.numberRequest,A.yearRequest,A.justification,A.dateSend,A.modified,A.keyStat,A.requestedAmount,B.nameStat,A.keyStat",
      conditions: `A.enabled = 1 AND A.keyStat = 119 AND A.idArea =${idArea} and A.idBudgetRequest NOT IN (SELECT idBudgetRequest FROM purchaserequests WHERE enabled = 1 AND keyStat != 124)`,
      order,
      records: records.toString(),
      page: (page * records).toString(),
      search,
    };
    return params;
  };

  async function getData2(order, records, page, search) {
    try {
      const res = await API.peticion(
        getParamsSuficiencia(order, records, page, search)
      );
      if (res.status === 200 && res.data.code === "200") {
        return res.data;
      } else {
        toast(
          <Notification
            type={"consultar_error"}
            backMessage={res.data.message}
          />
        );
        return null;
      }
    } catch (err) {
      toast(<Notification type={"consultar_servidor_error"} withIcon />);
      return null;
    }
  }

  const defaultForm = {
    document: File,
    idStaffRequire: "",
    nameStaff: "",
  };

  const FormSchema = Yup.object().shape({
    document: Yup.mixed()
      .required("Cargue un documento")
      .test("file-type", "El documento debe ser en formato PDF", (value) => {
        return value && value.type === "application/pdf";
      })
      .test({
        message: `El documento debe pesar menos de 4MB`,
        test: (value) => value?.size < 4000000,
      }),
    idStaffRequire: Yup.string().required(
      "Es necesario seleccionar el empleado que realiza la solicitud"
    ),
  });

  const {
    handleSubmit,
    values,
    handleBlur,
    errors,
    touched,
    setFieldValue,
    resetForm,
  } = useFormik({
    initialValues: defaultForm,
    onSubmit: () => {
      setModalConfirmation(true);
    },
    validationSchema: FormSchema,
    enableReinitialize: true,
  });

  const onFileChange = ({ target }) => {
    if (target.files.length === 0) {
      setFieldValue(target.name, "");
    } else {
      setFieldValue(target.name, target.files[0]);
    }
  };

  function regresar() {
    history.push("/template/solicitud-adquisicion");
  }

  async function getDocument(idBudgetRequest) {
    setSolicitud(null);
    setCalend(null);
    setTotal(null);
    setProyP(null);
    setNotes(null);
    setModalReport(true);
    const params = {
      action: "multiselect",
      table:
        "budgetrequests A INNER JOIN areas B USING (idArea) INNER JOIN staff C ON C.idStaff = A.idStaffRequest INNER JOIN status D ON D.keyStat = A.keyStat",
      rows: "B.name AS nameArea, CONCAT(A.numberRequest,'/',A.yearRequest) AS folio, A.justification, CONCAT(C.name,' ',C.pName,' ', C.mName) as fullName, A.dateSend, D.nameStat, A.requestedAmount,A.idBudgetRequest, A.keyStat, A.notes",
      conditions: `A.idBudgetRequest=${idBudgetRequest}`,
    };
    try {
      const res = await API.peticion(params);
      if (res.status === 200 && res.data.code === "200") {
        setSolicitud(res.data.data[0]);
        setNotes(res.data.data[0].notes);
        getMonths(idBudgetRequest);
        getProy(idBudgetRequest);
      } else {
        toast(
          <Notification
            type={"consultar_error"}
            backMessage={res.data.message}
          />
        );
        return null;
      }
    } catch (err) {
      toast(<Notification type={"consultar_servidor_error"} withIcon />);
      return null;
    }
  }

  async function getMonths(idBudgetRequest) {
    const params = {
      action: "multiselect",
      table:
        "budgetrequestdetail A INNER JOIN budgetrequestschedule B USING(idDetail) INNER JOIN months C USING (keyMonth)",
      rows: "C.nameMonth, SUM(IF(A.keyStat = 121, B.required, IF(A.keyStat = 122,B.approved, 0))) AS acum, C.keyMonth",
      conditions: `A.idBudgetRequest = ${idBudgetRequest} AND A.enabled = 1 AND B.enabled = 1`, // AND A.keyStat != 123
      order: "C.keyMonth",
      group: "C.keyMonth",
    };
    const res = await doRequest("receiver/receiver.php", params, false, false);
    if (res.length > 0) {
      let tt = 0;
      const data = mNames.map((el, ix) => {
        const fnd = res.find((mb) => +mb.keyMonth === ix + 1);
        const nMes = { nameMonth: el, acum: 0 };
        if (fnd) {
          tt += +fnd.acum;
          nMes.acum = +fnd.acum;
        }
        return nMes;
      });

      setCalend(data);
      setTotal(tt);
    }
  }

  async function getProy(idBudgetRequest) {
    const params = {
      action: "multiselect",
      table:
        "budgetrequestdetail A INNER JOIN statefunds B USING (idStateFund) INNER JOIN expense_objects C USING (idExpenseObject) INNER JOIN budgetrequestschedule G USING (idDetail) INNER JOIN status H USING (keyStat) INNER JOIN budgetrequests I USING (idBudgetRequest) LEFT JOIN staff SS ON SS.idStaff = I.idStaffRequest LEFT JOIN staff SR ON SR.idStaff = I.idStaffRevision LEFT JOIN staff SA ON SA.idStaff = I.idStaffAproves",
      rows: "A.idStateFund, CONCAT(B.stateFundKey,' - ',B.nameStateFund) AS prPres, C.fullKey as COG, GROUP_CONCAT(G.required) AS rMonth, C.nameObject, SUM(G.required) AS acum, H.nameStat, A.idDetail,A.keyStat,A.concept, CONCAT(SS.name,' ',SS.pName,' ',SS.mName) AS solicita, CONCAT(SR.name,' ',SR.pName,' ',SR.mName) AS revisa, CONCAT(SA.name,' ',SA.pName,' ',SA.mName) AS aprueba",
      conditions: `A.idBudgetRequest = ${idBudgetRequest} AND A.enabled = 1 AND G.enabled = 1`,
      order: "prPres",
      group: "C.idExpenseObject, B.idStateFund",
    };
    const res = await doRequest("receiver/receiver.php", params, false, false);
    if (res.length > 0) {
      setProyP(res);
    }
  }

  async function sendData(values) {
    const res = await uploadFile(
      idPurchaseRequest,
      "saveFileReplace",
      57,
      values.document,
      true,
      true
    );
    if (res) {
      const params = {
        action: "sendPurchaseRequest",
        idPurchaseRequest,
        idStaffRequire: values.idStaffRequire,
      };
      try {
        const resp = await API.peticionEndPoint(
          params,
          "app/facades/budgets/budgetsF.php"
        );
        if (resp.status === 200 && resp.data.code === "200") {
          toast(
            <Notification
              type={"agrega_exito"}
              backMessage={resp.data.message}
              withIcon
            />
          );
          history.push("/template/solicitud-adquisicion");
        } else {
          toast(
            <Notification
              type={"agrega_error"}
              backMessage={resp.data.message}
            />
          );
        }
      } catch (err) {
        console.log(err);
        toast(<Notification type={"consultar_servidor_error"} withIcon />);
      }
    }
  }

  const cabecerasMTEmp = [
    "#",
    "Núm. Empleado",
    "Nombre",
    "Unidad de trabajo",
    "Puesto",
    "Seleccionar",
  ];

  const filtroMTEmp = [true, true, true, true, true];
  const paramsMTEmp = {
    action: "datatable",
    table:
      "staff STF INNER JOIN employtypes AS EMT ON STF.keyEmployType = EMT.keyEmployType INNER JOIN jobstaff AS JBS ON JBS.idStaff = STF.idStaff INNER JOIN areas AS AR ON AR.idArea = JBS.idArea INNER JOIN jobtypes AS JBT ON JBT.keyJobType = STF.keyEmployType",
    rows: "STF.idStaff,STF.staffNumber,CONCAT(STF.name,' ',STF.pName,' ',STF.mName) as fullName,AR.name,JBT.nameJobType",
    conditions:
      "STF.enabled = 1 AND JBS.valid = 1 AND STF.keyStat = 1 AND JBS.enabled = 1",
    page: 0,
    records: 5,
    search: "",
    order: "",
  };

  const selectEmpleado = (el) => {
    const employee = { idStaff: el[0], nameStaff: `${el[1]} - ${el[2]}` };
    setFieldValue("idStaffRequire", employee.idStaff);
    setFieldValue("nameStaff", employee.nameStaff);
  };

  const clearEmployee = () => {
    setFieldValue("idStaffRequire", "");
    setFieldValue("nameStaff", "");
  };

  if (loading || idArea === "") {
    return (
      <div
        className="d-flex align-items-center justify-content-center"
        style={{ height: "200px" }}
      >
        <Spinner
          color="primary"
          style={{
            height: "3rem",
            width: "3rem",
          }}
          type="grow"
        >
          Loading...
        </Spinner>
      </div>
    );
  } else if (!permisos.INS) {
    return (
      <>
        <div className="text-center p-4">
          No tienes permitido ver los registros de esta seccion
        </div>
      </>
    );
  }
  return (
    <>
      <Widget className={"widget-p-md"}>
        <h3 className="text-secondary mb-3">Nuevo registro</h3>
        <Row>
          <Col sm={12} md={6}>
            <h4 className="mb-3">Cotizaciones disponibles</h4>
            <Datatable
              columns={columns}
              headers={headers}
              petition={getData}
              control="back"
              className="p-0"
            />
          </Col>
          <Col sm={12} md={6}>
            <h4 className="mb-3">Suficiencias disponibles</h4>
            <Datatable
              columns={columns2}
              columnDefs={columnDefs2}
              headers={headers2}
              order={{ col: 1, opt: "asc" }}
              petition={getData2}
              control="back"
              className="p-0"
            />
          </Col>
        </Row>
        <h4>Solicitud</h4>
        <InputWithModal
          cabeceras={cabecerasMTEmp}
          filtro={filtroMTEmp}
          actionSelect={selectEmpleado}
          onClear={clearEmployee}
          parametros={paramsMTEmp}
          value={values.nameStaff ? values.nameStaff : ""}
          label="Empleado que mandara la solicitud"
          name={"nameStaff"}
          title={"Empleado que mandara la solicitud"}
          disabled={idPurchaseRequest === ""}
          error={errors.idStaffRequire}
          touched={touched.idStaffRequire}
        />
        <div className="text-center mt-3">
          <ButtonFile
            label="Adjuntar oficio de solicitud"
            error={errors.document}
            name="document"
            value={values.document}
            color="primary"
            onChange={onFileChange}
            disabled={idPurchaseRequest === ""}
          />
        </div>
        <form onSubmit={handleSubmit}>
          <div className="text-center mt-3">
            <Button disabled={idPurchaseRequest === ""}>
              Enviar solicitud
            </Button>
          </div>
        </form>
        <div className="text-center mt-3">
          <Button color="danger" onClick={regresar}>
            Regresar
          </Button>
        </div>
      </Widget>
      {idBudgetRequest !== "" && idQuote !== "" && idArea !== "" && (
        <ModalCotizacion
          setVisible={showModal}
          visible={modal}
          idBudgetRequest={idBudgetRequest}
          idQuote={idQuote}
          idArea={idArea}
          idPurchaseRequest={idPurchaseRequest}
          setIdPurchaseRequest={setIdPurchaseRequest}
          setIdQuote={setIdQuote}
          setIdBudgetRequest={setIdBudgetRequest}
          added={added}
          setAdded={setAdded}
        />
      )}
      {linkFile && (
        <ModalViewFile
          modal={modalFile}
          setModal={setModalFile}
          backdrop={true}
          keyboard={true}
          btnClose={true}
          fileLink={linkFile}
        />
      )}
      {calend && proyP && solicitud && total && (
        <ModalReports
          modal={modalReport}
          setModal={setModalReport}
          title={"Solicitud de suficiencia presupuestal y dictamen " + ""}
          backdrop
          keyboard
          report={{
            meses: calend,
            proyects: proyP,
            solicitud: solicitud,
            total: total,
            notas: notes,
          }}
          pdfConfig={{}}
          generatePdfMethod={{
            method: pdfSolicitud,
            type: "reporteAuxiliar",
          }}
        />
      )}
      {modalReport && (
        <FullFormLoader
          show={
            calend === null ||
            proyP === null ||
            solicitud === null ||
            total === null
          }
          message=""
        />
      )}
      <ModalConfirmation
        modalTitle={"Enviar solicitud"}
        modal={modalConfirmation}
        setModal={setModalConfirmation}
        crear={sendData}
        values={values}
      >
        <div className="d-flex justify-content-center">
          <h6>¿Desea continuar con el registro?</h6>
        </div>
      </ModalConfirmation>
    </>
  );
}

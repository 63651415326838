import { useEffect, useState } from "react";
import TableComponentCustom from "../../../components/TableComponent/TableComponentCustom";

const cabeceras = [
  "Id",
  "RAZÓN SOCIAL",
  "RFC",
  "TELÉFONO",
  "CORREO",
  "TIPO",
  "Ver",
];
const filtro = [true, true, true, true, true, true];

const Proveedores = ({ setActiveTab, setProvider, contratist }) => {
  const [parametros] = useState({
    action: "datatable",
    table: "providers A JOIN types_providers B USING (keyTypeProvider)",
    rows: "A.idProvider, A.socialName, A.itin, A.telephone, A.email, B.nameTypeProvider, A.keyTypeProvider, A.webPage",
    conditions: `A.enabled = 1`,
    page: 0,
    records: 5,
    search: "",
    order: "",
  });

  const createProvider = () => {
    setActiveTab(2);
  };

  const selectProvider = (el) => {
    setProvider({ id: [...el][0], info: [...el] });
  };

  return (
    <div>
      <TableComponentCustom
        cabecerasTabla={cabeceras}
        filtro={filtro}
        parametros={parametros}
        addNew={true}
        actionNew={createProvider}
        addWatch
        actionWatch={selectProvider}
        hiddeTitle
      />
    </div>
  );
};

export default Proveedores;

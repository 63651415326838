import Widget from "../../../components/Widget/Widget";
import { Nav, NavItem, NavLink } from "reactstrap";
import { useState } from "react";
import MostrarFirmados from "./MostrarFirmados";
import { GetPermissions } from "../../../libs/permissions/getPermissions";
import FirmaFUMP from "./FirmaFUMP";
import FirmaOtros from "./FirmaOtros";

const st = {
  in: { fontSize: "19px", marginRight: "5px" },
  imr: { marginRight: "5px" },
  bC: { backgroundColor: "#cc0a44", border: "none", color: "#FFFFFF" },
  iB: { fontSize: "16px" },
};

const titles = ["Firmar FUMP", "Firmar Archivos", "Archivos Firmados"];

export default function FirmaArchivos() {
  const [permissions, setPermissions] = useState({});
  GetPermissions(setPermissions);

  const [activeTab, setActiveTab] = useState(0);

  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  return (
    <Widget className="widget-p-md">
      <Nav fill tabs style={{ overflowY: "hidden", overflowX: "auto" }}>
        <NavItem active={activeTab === 0}>
          <NavLink
            href="#"
            active={activeTab === 0}
            onClick={() => toggleTab(0)}
          >
            <i className={"fa fa-pencil-square-o"} style={st.in} />
            {titles[0]}
          </NavLink>
        </NavItem>
        <NavItem active={activeTab === 1}>
          <NavLink
            href="#"
            active={activeTab === 1}
            onClick={() => toggleTab(1)}
          >
            <i className={"eva eva-edit-2-outline"} style={st.in} />
            {titles[1]}
          </NavLink>
        </NavItem>
        <NavItem active={activeTab === 2}>
          <NavLink
            href="#"
            active={activeTab === 2}
            onClick={() => toggleTab(2)}
          >
            <i className={"eva eva-edit-2-outline"} style={st.in} />
            {titles[2]}
          </NavLink>
        </NavItem>
      </Nav>
      <div className="mt-4">
        <div className="mx-4 text-center text-md-left">
          <h3>{titles[activeTab]}</h3>
        </div>
        {+activeTab === 0 ? (
          <FirmaFUMP permissions={permissions} />
        ) : activeTab === 1 ? (
          <FirmaOtros permissions={permissions} />
        ) : (
          <MostrarFirmados permissions={permissions} />
        )}
      </div>
    </Widget>
  );
}
